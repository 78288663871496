var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "page-filters-section",
        { attrs: { overlay: false } },
        _vm._l(_vm.filterElementOptions, function (elem, i) {
          return _c(
            "v-btn",
            {
              key: i,
              staticClass: "text-capitalize",
              class:
                _vm.filters.element.toLowerCase() === elem ? "current" : "",
              on: {
                click: function ($event) {
                  return _vm.filterByElement(elem)
                },
              },
            },
            [_vm._v(" " + _vm._s(elem) + " ")]
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          !_vm.isWalletConnected
            ? _c(
                "v-row",
                { staticClass: "pt-15" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [_c("connect-wallet")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isWalletConnected
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    [
                      _vm.loadingWizards
                        ? _c("v-progress-circular", {
                            staticStyle: { width: "100% !important" },
                            attrs: { size: "300", indeterminate: "" },
                          })
                        : _vm._e(),
                      0 === _vm.wizards.length && !_vm.loadingWizards
                        ? _c("v-col", [
                            _c("h3", { staticClass: "text-center mt-10" }, [
                              _vm._v("- No Wizards -"),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.parsedWizards, function (wizard, i) {
                        return _c(
                          "div",
                          {
                            key: `${i}_${wizard.image}`,
                            staticClass: "pa-1 ma-0 d-inline-block",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "Wizard",
                                    params: { id: wizard.id },
                                  },
                                },
                              },
                              [
                                _c("mini-wizard", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.shouldDisplayMiniWizard(wizard),
                                      expression:
                                        "shouldDisplayMiniWizard(wizard)",
                                    },
                                  ],
                                  attrs: { item: wizard },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _vm.parsedWizards.length > 0
                    ? _c(
                        "v-row",
                        { staticClass: "text-center mt-15 mb-5" },
                        [
                          _c("v-pagination", {
                            staticClass: "mx-auto",
                            attrs: {
                              length: _vm.paginationLength,
                              "total-visible": "10",
                            },
                            on: { input: _vm.onPageChange },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }