<template>
    <v-container>
        <page-filters-section :overlay="false">
            <v-btn v-for="(elem, i) in filterElementOptions"
                   :key="i"
                   class="text-capitalize"
                   @click="filterByElement(elem)"
                   v-bind:class="filters.element.toLowerCase() === elem ? 'current': ''"
            >
                {{ elem }}
            </v-btn>
        </page-filters-section>

        <div class="page-content">
            <v-row v-if="!isWalletConnected" class="pt-15">
                <v-col cols="12">
                    <connect-wallet></connect-wallet>
                </v-col>
            </v-row>

            <div v-if="isWalletConnected">
                <v-row>
                    <v-progress-circular
                        v-if="loadingWizards"
                        size="300"
                        indeterminate
                        style="width: 100% !important;"
                    ></v-progress-circular>
                    <v-col v-if="0 === wizards.length && !loadingWizards">
                        <h3 class="text-center mt-10">- No Wizards -</h3>
                    </v-col>
                    <div v-for="(wizard, i) in parsedWizards" :key="`${i}_${wizard.image}`"
                         class="pa-1 ma-0 d-inline-block">
                        <router-link :to="{ name: 'Wizard', params: {id: wizard.id}}">
                            <mini-wizard
                                :item="wizard"
                                v-show="shouldDisplayMiniWizard(wizard)"
                            ></mini-wizard>
                        </router-link>
                    </div>
                </v-row>

                <v-row v-if="parsedWizards.length > 0" class="text-center mt-15 mb-5">
                    <v-pagination
                        class="mx-auto"
                        v-model="page"
                        :length="paginationLength"
                        total-visible="10"
                        @input="onPageChange"
                    ></v-pagination>
                </v-row>
            </div>
        </div>

    </v-container>
</template>

<script>
import MiniWizard from "../components/assets/wizards/mini-wizard";
import PageFiltersSection from "../components/page-filters-section";
import {NoWalletError} from "../errors/WalletErrors";
import {apiServiceVue} from "../services/apiService.vue";
import ConnectWallet from "@/views/ConnectWallet";

const ITEMS_PER_PAGE = 33;

export default {
    components: {ConnectWallet, PageFiltersSection, MiniWizard},
    data() {
        return {
            page: 1,
            totalItems: 0,
            wizards: [],
            filterElementOptions: ['all'],
            filters: {
                element: 'all',
            },
            loadingWizards: true,
        };
    },
    watch: {},
    mounted: function () {
        if (this.isWalletConnected) {
            this.onPageChange();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.onPageChange();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        parsedWizards() {
            return this.wizards;
        },
        paginationLength() {
            return Math.ceil(this.totalItems / ITEMS_PER_PAGE);
        }
    },
    methods: {
        onPageChange() {
            this.fetchMyWizards((this.page - 1) * ITEMS_PER_PAGE, ITEMS_PER_PAGE);
        },
        shouldDisplayMiniWizard(wizard) {
            return this.filters.element !== 'all' ? wizard.wizarre.element.toLowerCase() === this.filters.element.toLowerCase() : true;
        },
        filterByElement(element) {
            this.filters.element = element;
        },

        async fetchMyWizards(offset = 0, limit = 100) {
            if (!this.web3.address) {
                throw new NoWalletError();
            }

            this.loadingWizards = true;
            this.wizards = [];

            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'wallet/' + this.web3.address;
            apiServiceVue.get(url, {
                offset: offset,
                limit: limit,
                withTotal: true,
            })
                .then(res => {
                    this.totalItems = res.data.total;
                    const wizards = res.data.items;
                    if (wizards && wizards.length > 0) {
                        this.wizards = wizards;
                    }
                    this.loadingWizards = false;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch Wizards failed'
                    });
                });
        }
    }
}
</script>

<style scoped>
>>> .top-section .v-btn.current .v-btn__content {
    color: var(--v-primary-base);
}

.page-content {
    margin-top: 200px;
}
</style>
